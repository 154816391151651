<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <!-- logo -->

        <b-card-title class="mb-1">
          Set New Password 🔒
        </b-card-title>
        <b-card-text
          class="mb-2"
        >
          Set your new password below to access your account.
        </b-card-text>

        <!-- form -->
        <validation-observer ref="resetForm">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="resetPassword"
          >
            <!-- password -->
            <b-form-group
              label="New Password"
              label-for="reset-password-new"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="userPassword"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Confirm Password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="confirmPassword"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <SpinnerBtn v-if="isLoading" />
            <b-button
              v-else
              block
              type="submit"
              variant="primary"
            >
              Set New Password
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{ name: 'auth-login' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </p>
      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { required, password } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SpinnerBtn from '@/layouts/components/spinners/SpinnerButton.vue'

export default {
  components: {
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    SpinnerBtn,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      id_type: null,
      show: false,
      userID: null,
      email_address: '',
      reset_link: '',
      userPassword: '',
      confirmPassword: '',
      isLoading: false,
      required,
      password,
      // Toggle Password
      passwordFieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  created() {
    this.id_type = this.$route.query.id
    this.reset_link = this.$route.params.code
    this.confirm_code(this.$route.params.code)
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    checkEvent() {
      const currentEvent = localStorage.getItem('current_event')
        ? localStorage.getItem('current_event')
        : ''

      if (currentEvent) {
        if (currentEvent.length > 0) {
          return true
        }
        return false
      }
      return false
    },
    resetPassword() {
      this.isLoading = true
      this.$refs.resetForm.validate().then(success => {
        if (success) {
          this.$http
            .post(`/api/auth/confirm-reset/${this.reset_link}`, {
              user: { id: this.userID, password: this.userPassword },
            })
            .then(response => {
              this.isLoading = false
              const cleaner = response.data

              if (cleaner.success) {
                this.show = true

                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Password Notification',
                      icon: 'AlertCircleIcon',
                      text: 'Your new password has been set successfully. You can now proceed to login.',
                      variant: 'success',
                    },
                  },
                  { timeout: this.$longestTimeout },
                )
                this.$router.push('/auth/login')
              } else if (cleaner.password_error) {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Password Error',
                      icon: 'AlertCircleIcon',
                      text: "Your password link is invalid or has expired.",
                      variant: 'danger',
                    },
                  },
                  { timeout: this.$longestTimeout },
                )
              } else if (!cleaner.success || cleaner.errors) {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Password Error',
                      icon: 'AlertCircleIcon',
                      text: "Your password link is invalid or has expired.",
                      variant: 'danger',
                    },
                  },
                  { timeout: this.$longestTimeout },
                )
                this.$router.push('/auth/login')
              }
            })
            .catch(error => {
              this.isLoading = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Password Error',
                    icon: 'AlertCircleIcon',
                    text: "Your password link is invalid or has expired.",
                    variant: 'danger',
                  },
                },
                { timeout: this.$longestTimeout },
              )
              this.$router.push('/auth/login')
              //   this.$router.push(this.$router.currentRoute.query.to || '/auth/login')
            })
        }
      })
    },
    confirm_code(code) {
      this.$http
        .get(`/api/auth/confirm-reset/${code}`)
        .then(response => {
          const record = response.data
          if (record.success) {
            this.show = true
            this.userID = record.id
            // this.$toast(
            //   {
            //     component: ToastificationContent,
            //     props: {
            //       title: 'Confirmation Link Valid',
            //       icon: 'AlertCircleIcon',
            //       text: 'You can now fill the form to reset your password.',
            //       variant: 'success',
            //     },
            //   },
            //   { timeout: this.$longestTimeout },
            // )
          } else if (!record.success || record.errors) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Password Link Invalid',
                  icon: 'AlertCircleIcon',
                  text: "Your password link is invalid or has expired.",
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
            this.$router.push('/auth/login')
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Password Link Invalid',
                icon: 'AlertCircleIcon',
                text: "Your password link is invalid or has expired.",
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )

          this.$router.push('/auth/login')
          //   this.$router.push(this.$router.currentRoute.query.to || '/auth/login')
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
